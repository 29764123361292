import { Route, Routes } from "react-router-dom";
import AdminBody from "./Pages/admin/AdminBody";
import Overview from "./Pages/admin/Overview";
import Partners from "./Pages/Dashboard/Partners/Partners";
import "./App.css";
import Users from "./Pages/Dashboard/Users/Users";
import Tiffincatering from "./Pages/Dashboard/Tiffin & catering/Tiffincatering";
import Coupons from "./Pages/Dashboard/Coupons/Coupons";
import Mybank from "./Pages/Dashboard/My bank/Mybank";
import Visitingcards from "./Pages/Dashboard/Visiting cards/Visitingcards";
import Ads from "./Pages/Dashboard/Ads/Ads";
import ReferandEarn from "./Pages/Dashboard/ReferandEarn/ReferandEarn";
import Modal from "./Pages/Dashboard/AllModals/Modal";
import Chat from "./Pages/Dashboard/Chat/Chat";
import Account from "./Pages/Dashboard/Accounts/Account";
import Payments from "./Pages/Dashboard/Payments/Payments";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<AdminBody />}>
          <Route index element={<Overview />} />
          <Route path="Partners" element={<Partners />} />
          <Route path="Users" element={<Users />} />
          <Route path="Tiffincatering" element={<Tiffincatering />} />
          <Route path="Coupons" element={<Coupons />} />

          <Route path="MyBank" element={<Mybank />} />
          <Route path="Visitingcards" element={<Visitingcards />} />
          <Route path="Ads" element={<Ads />} />
          <Route path="ReferandEarn" element={<ReferandEarn />} />
          <Route path="Chat" element={<Chat />} />

          <Route path="Modal" element={<Modal />} />
          <Route path="Account" element={<Account />} />
          <Route path="payment" element={<Payments />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
