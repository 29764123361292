import React from 'react'
import { useMediaQuery } from 'react-responsive'

const Header = () => {
    const isLaptop = useMediaQuery({
        query: '(max-width: 1023px)'
      })
      
  return (

    
    <div className="flex  flex-1 flex-wrap justify-between items-center mt-20 lg:mt-0">
          {/* ---------------- NAVBAR - LEFT ---------------- */}
          <div className="flex flex-col ">
          <p className=" text-[24px] text-[#3B3B3B] font-medium" >Welcome, Amanda</p>
<p className="text-[#686868] text-[16px] text-start font-light mt-1">Tue, 07 June 2022</p>

         
          </div>

          {/* ---------------- NAVBAR - RIGHT ---------------- */}
          {
            !isLaptop &&  
          
      <div className="flex flex-2 gap-10 justify-end items-center">

        
<form class=" max-w-lg mx-auto">   
  <div class="relative w-full ">
      <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke="#949494" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 22L20 20" stroke="#949494" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


      </div>
      <input type="text" id="voice-search" class="bg-white border-none text-gray-900 text-sm rounded-[10px] focus:ring-blue-500 focus:border-blue-500 block w-full ps-12 py-2.5  " placeholder="Search " required />
     
  </div>

</form>


      <button
          type="button"
          data-dropdown-toggle="notification-dropdown"
          class="p-2 mr-1 text-gray-500 rounded-[10px] bg-white hover:text-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 "
        >
          
            
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 6.43994V9.76994" stroke="#949494" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M12.0189 2C8.33892 2 5.35892 4.98 5.35892 8.66V10.76C5.35892 11.44 5.07892 12.46 4.72892 13.04L3.45892 15.16C2.67892 16.47 3.21892 17.93 4.65892 18.41C9.43892 20 14.6089 20 19.3889 18.41C20.7389 17.96 21.3189 16.38 20.5889 15.16L19.3189 13.04C18.9689 12.46 18.6889 11.43 18.6889 10.76V8.66C18.6789 5 15.6789 2 12.0189 2Z" stroke="#949494" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M15.3319 18.8198C15.3319 20.6498 13.8319 22.1498 12.0019 22.1498C11.0919 22.1498 10.2519 21.7698 9.65187 21.1698C9.05187 20.5698 8.67188 19.7298 8.67188 18.8198" stroke="#949494" stroke-width="1.5" stroke-miterlimit="10"/>
</svg>
        </button>
        <button
          type="button"
          class="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 "
          id="user-menu-button"
          aria-expanded="false"
          data-dropdown-toggle="dropdown"
        >
          <img
            class="w-8 h-8 object-cover rounded-[10px]"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/michael-gough.png"
            alt="user photo"
          />
        </button>


      </div>
        
          }
        </div>
  )
}

export default Header