import React, { useRef } from 'react'

const Tiffins = () => {
    const ref = useRef();

  return (
    <div  className="bg-[#FFFFFF] rounded-[10px]  px-[16px] py-[20px] mt-[2rem]" >
    

    {/* card header */}
    <div className="flex flex-wrap gap-2 justify-between items-center">  
    <div className="flex flex-col gap-1 ">   
          <p className="text-[16px] text-[#3B3B3B] font-semibold ">Tiffins</p>
    <div className="flex items-center gap-2">
    
    <span className="bg-[#45A843] w-[12px] h-[12px] rounded-full"></span>
    <p className="text-[12px] text-[#949494] font-normal font-Roboto "> $32K(Total revenue) </p>
    </div>
   
    
          </div>
    
    <div className=" flex flex-wrap  items-center gap-2 ">    
    
    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.61552 12.2968C5.61552 11.6506 5.16662 11.2646 4.3751 11.2646C4.05202 11.2646 3.83353 11.2961 3.71875 11.3267V13.402C3.85478 13.4326 4.02142 13.4437 4.25012 13.4437C5.09436 13.4437 5.61552 13.016 5.61552 12.2968ZM10.5177 11.2851C10.1632 11.2851 9.93364 11.3165 9.79761 11.348V15.9458C9.93364 15.9772 10.1521 15.9772 10.3502 15.9772C11.7887 15.9875 12.7274 15.1951 12.7274 13.5168C12.7384 12.057 11.8831 11.2851 10.5177 11.2851Z" fill="#F33F41"/>
    <path d="M19.9097 8.58436H19.3333V5.80339C19.3328 5.78573 19.3311 5.76811 19.3282 5.75068C19.3291 5.63883 19.2896 5.53043 19.2168 5.44546L14.5893 0.160686L14.5859 0.157285C14.5271 0.0935133 14.4522 0.0468071 14.3691 0.0221049L14.3435 0.0136029C14.3082 0.00479575 14.272 0.000229389 14.2356 0H2.86259C2.34312 0 1.92143 0.422544 1.92143 0.94116V8.58436H1.345C0.601934 8.58436 0 9.1863 0 9.92936V16.9247C0 17.6669 0.602784 18.2697 1.345 18.2697H1.92143V23.0588C1.92143 23.5775 2.34312 24 2.86259 24H18.3921C18.9108 24 19.3333 23.5775 19.3333 23.0588V18.2697H19.9097C20.6528 18.2697 21.2547 17.6669 21.2547 16.9247V9.92936C21.2547 9.1863 20.652 8.58436 19.9097 8.58436ZM2.86259 0.94116H13.7646V5.75663C13.7646 6.01679 13.9754 6.22679 14.2356 6.22679H18.393V8.58436H2.86259V0.94116ZM14.4167 13.4644C14.4167 14.8095 13.927 15.7379 13.2477 16.3109C12.508 16.9256 11.3823 17.218 10.0059 17.218C9.18204 17.218 8.59882 17.1653 8.20178 17.1143V10.2116C8.78586 10.1181 9.54678 10.0662 10.3502 10.0662C11.6842 10.0662 12.5496 10.306 13.2272 10.817C13.9576 11.3585 14.4167 12.224 14.4167 13.4644ZM2.14333 17.1458V10.2116C2.63304 10.1283 3.32169 10.0662 4.29091 10.0662C5.27117 10.0662 5.97003 10.2541 6.43848 10.6291C6.88653 10.9836 7.18835 11.5677 7.18835 12.2555C7.18835 12.9441 6.95965 13.5274 6.54221 13.9236C5.99979 14.4345 5.19721 14.6641 4.2586 14.6641C4.0503 14.6641 3.86241 14.6539 3.71703 14.6326V17.1458H2.14333ZM18.3921 22.8038H2.86259V18.2697H18.3921V22.8038ZM19.7559 11.4214H17.0548V13.0275H19.5782V14.3206H17.0548V17.1458H15.4607V10.1181H19.7559V11.4214Z" fill="#F33F41"/>
    </svg>
   


    <input    type="date"     placeholder="Today" name="" id="" className="border border-1 border-[#686868] text-[14px] text-[#44444F] font-normal rounded-[8px] p-[6px]" />
    
    </div>
    
    
          </div>
    
{/* rows */}
<div className="Rows_Container mt-4">  

<div className="flex flex-wrap justify-between items-center border-b border-[#F1F1F5] border-1 py-[20px] ">
<div className="flex items-center">   
<div className="flex justify-center items-center bg-[#F3F3F3] rounded-full w-[44px] h-[44px]">
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.58078 19.0103L2.56078 19.0303C2.29078 18.4403 2.12078 17.7703 2.05078 17.0303C2.12078 17.7603 2.31078 18.4203 2.58078 19.0103Z" fill="#292D32"/>
<path d="M9.00109 10.3801C10.3155 10.3801 11.3811 9.31456 11.3811 8.00012C11.3811 6.68568 10.3155 5.62012 9.00109 5.62012C7.68666 5.62012 6.62109 6.68568 6.62109 8.00012C6.62109 9.31456 7.68666 10.3801 9.00109 10.3801Z" fill="#292D32"/>
<path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03C3.42 20.93 5.26 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V13.9V7.81C22 4.17 19.83 2 16.19 2ZM20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L3.85 18.16C3.63 17.6 3.5 16.95 3.5 16.19V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16.19C19.01 3.5 20.5 4.99 20.5 7.81V12.61L20.37 12.5Z" fill="#292D32"/>
</svg>

</div>

<div className='ml-6 sm:ml-12'>
    <p className='text-[#000000] text-[16px] font-normal'>Jack smith(Zaika)</p>
    <p  className='text-[#949494] text-[12px] font-medium'> Basic package($220) </p>

</div>
</div>

<p  className='text-[#45A843] text-[16px] font-medium'> $220 </p>

</div>
<div className="flex flex-wrap justify-between items-center border-b border-[#F1F1F5] border-1 py-[20px] ">
<div className="flex items-center">   
<div className="flex justify-center items-center bg-[#F3F3F3] rounded-full w-[44px] h-[44px]">
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.58078 19.0103L2.56078 19.0303C2.29078 18.4403 2.12078 17.7703 2.05078 17.0303C2.12078 17.7603 2.31078 18.4203 2.58078 19.0103Z" fill="#292D32"/>
<path d="M9.00109 10.3801C10.3155 10.3801 11.3811 9.31456 11.3811 8.00012C11.3811 6.68568 10.3155 5.62012 9.00109 5.62012C7.68666 5.62012 6.62109 6.68568 6.62109 8.00012C6.62109 9.31456 7.68666 10.3801 9.00109 10.3801Z" fill="#292D32"/>
<path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03C3.42 20.93 5.26 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V13.9V7.81C22 4.17 19.83 2 16.19 2ZM20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L3.85 18.16C3.63 17.6 3.5 16.95 3.5 16.19V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16.19C19.01 3.5 20.5 4.99 20.5 7.81V12.61L20.37 12.5Z" fill="#292D32"/>
</svg>

</div>

<div className='ml-6 sm:ml-12'>
    <p className='text-[#000000] text-[16px] font-normal'>Jack smith(Zaika)</p>
    <p  className='text-[#949494] text-[12px] font-medium'> Basic package($220) </p>

</div>
</div>

<p  className='text-[#45A843] text-[16px] font-medium'> $220 </p>

</div>
<div className="flex flex-wrap justify-between items-center border-b border-[#F1F1F5] border-1 py-[20px] ">
<div className="flex items-center">   
<div className="flex justify-center items-center bg-[#F3F3F3] rounded-full w-[44px] h-[44px]">
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.58078 19.0103L2.56078 19.0303C2.29078 18.4403 2.12078 17.7703 2.05078 17.0303C2.12078 17.7603 2.31078 18.4203 2.58078 19.0103Z" fill="#292D32"/>
<path d="M9.00109 10.3801C10.3155 10.3801 11.3811 9.31456 11.3811 8.00012C11.3811 6.68568 10.3155 5.62012 9.00109 5.62012C7.68666 5.62012 6.62109 6.68568 6.62109 8.00012C6.62109 9.31456 7.68666 10.3801 9.00109 10.3801Z" fill="#292D32"/>
<path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03C3.42 20.93 5.26 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V13.9V7.81C22 4.17 19.83 2 16.19 2ZM20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L3.85 18.16C3.63 17.6 3.5 16.95 3.5 16.19V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16.19C19.01 3.5 20.5 4.99 20.5 7.81V12.61L20.37 12.5Z" fill="#292D32"/>
</svg>

</div>

<div className='ml-6 sm:ml-12'>
    <p className='text-[#000000] text-[16px] font-normal'>Jack smith(Zaika)</p>
    <p  className='text-[#949494] text-[12px] font-medium'> Basic package($220) </p>

</div>
</div>

<p  className='text-[#45A843] text-[16px] font-medium'> $220 </p>

</div>
<div className="flex flex-wrap justify-between items-center border-b border-[#F1F1F5] border-1 py-[20px] ">
<div className="flex items-center">   
<div className="flex justify-center items-center bg-[#F3F3F3] rounded-full w-[44px] h-[44px]">
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.58078 19.0103L2.56078 19.0303C2.29078 18.4403 2.12078 17.7703 2.05078 17.0303C2.12078 17.7603 2.31078 18.4203 2.58078 19.0103Z" fill="#292D32"/>
<path d="M9.00109 10.3801C10.3155 10.3801 11.3811 9.31456 11.3811 8.00012C11.3811 6.68568 10.3155 5.62012 9.00109 5.62012C7.68666 5.62012 6.62109 6.68568 6.62109 8.00012C6.62109 9.31456 7.68666 10.3801 9.00109 10.3801Z" fill="#292D32"/>
<path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03C3.42 20.93 5.26 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V13.9V7.81C22 4.17 19.83 2 16.19 2ZM20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L3.85 18.16C3.63 17.6 3.5 16.95 3.5 16.19V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16.19C19.01 3.5 20.5 4.99 20.5 7.81V12.61L20.37 12.5Z" fill="#292D32"/>
</svg>

</div>

<div className='ml-6 sm:ml-12'>
    <p className='text-[#000000] text-[16px] font-normal'>Jack smith(Zaika)</p>
    <p  className='text-[#949494] text-[12px] font-medium'> Basic package($220) </p>

</div>
</div>

<p  className='text-[#45A843] text-[16px] font-medium'> $220 </p>

</div>
<div className="flex flex-wrap justify-between items-center border-b border-[#F1F1F5] border-1 py-[20px] ">
<div className="flex items-center">   
<div className="flex justify-center items-center bg-[#F3F3F3] rounded-full w-[44px] h-[44px]">
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.58078 19.0103L2.56078 19.0303C2.29078 18.4403 2.12078 17.7703 2.05078 17.0303C2.12078 17.7603 2.31078 18.4203 2.58078 19.0103Z" fill="#292D32"/>
<path d="M9.00109 10.3801C10.3155 10.3801 11.3811 9.31456 11.3811 8.00012C11.3811 6.68568 10.3155 5.62012 9.00109 5.62012C7.68666 5.62012 6.62109 6.68568 6.62109 8.00012C6.62109 9.31456 7.68666 10.3801 9.00109 10.3801Z" fill="#292D32"/>
<path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03C3.42 20.93 5.26 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V13.9V7.81C22 4.17 19.83 2 16.19 2ZM20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L3.85 18.16C3.63 17.6 3.5 16.95 3.5 16.19V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16.19C19.01 3.5 20.5 4.99 20.5 7.81V12.61L20.37 12.5Z" fill="#292D32"/>
</svg>

</div>

<div className='ml-6 sm:ml-12'>
    <p className='text-[#000000] text-[16px] font-normal'>Jack smith(Zaika)</p>
    <p  className='text-[#949494] text-[12px] font-medium'> Basic package($220) </p>

</div>
</div>

<p  className='text-[#45A843] text-[16px] font-medium'> $220 </p>

</div>
     
</div>

    </div>
  )
}

export default Tiffins