import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive';

import UserignUpRate from '../../../Components/Users/UserignUpRate';
import Tiffinsellers from '../../../Components/Tiffin & catering/Tiffinsellers';
import Cateringservice from '../../../Components/Tiffin & catering/Cateringservice';
import Decorationservice from '../../../Components/Tiffin & catering/Decorationservice';
import TiffnSelleRight from '../../../Components/Tiffin & catering/TiffnSelleRight';
import CateringRigh from '../../../Components/Tiffin & catering/CateringRigh';
import Header from '../../../Components/Header/Header';
import TiffcateringRate from '../../../Components/Tiffin & catering/TiffcateringRate';
const Tiffincatering = () => {
    const isLaptop = useMediaQuery({
        query: '(max-width: 1023px)'
      })
     
    
  return (
    <section className={`p-3 sm:p-5 `}>



<Header/>
       

        <div className="  mt-4">   
        <p className=" text-[20px] text-[#3B3B3B] font-medium " >Tiffin & catering</p>
  
        </div>
    
  <div className="pt-6 grid grid-cols-1  lg:grid-cols-4 gap-2">
 {/* total Partners */}
    <div className=" bg-gradient-to-bl  from-[#FB6D72] to-[#F33F41] rounded-[12px] flex justify-between items-center px-[16px] py-[30px]">
    <div className="div">
    <p className="text-[16px] text-[#FFFFFF] font-medium "> Tiffin Partners</p>
      <p className="text-[24px] text-[#FFFFFF] font-medium mt-2">2324  </p>
    </div>
    <svg width="54" height="36" viewBox="0 0 54 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.0448533 35.9994C0.0448533 35.9994 -0.0448533 9.50768 0.0448533 9.28471C0.71305 7.62391 1.64938 6.02548 2.31549 5.91083C3.4488 5.71578 4.09481 8.36351 5.39707 8.36351C6.69933 8.36351 8.03245 6.09193 9.69507 5.91083C11.3577 5.72973 12.3905 7.44232 13.8543 7.44232C15.3181 7.44232 16.9958 0.993957 18.6322 0.993957C20.2687 0.993957 21.837 11.1271 24.0228 11.1271C26.2085 11.1271 26.652 2.54056 28.0265 2.54056C29.401 2.54056 30.3164 5.5769 31.6368 5.72659C32.9571 5.87629 32.9571 8.36351 34.0696 8.36351C35.1821 8.36351 36.7748 0.183594 38.5279 0.183594C40.2809 0.183594 42.3058 5.9936 43.5589 5.9936C44.8121 5.9936 44.1251 1.91515 45.6084 1.91515C47.0918 1.91515 46.0011 5.54236 47.485 5.72659C48.9689 5.91083 50.1776 7.44232 51.6208 7.44232C52.0372 7.44232 52.6085 7.11109 53.025 6.17784C53.4416 5.24459 53.8171 3.7449 53.8171 3.7449H54C54 3.7449 54 4.42194 54 4.61756C54 6.75574 54 35.9994 54 35.9994H0.0448533Z" fill="url(#paint0_linear_1349_2003)"/>
<defs>
<linearGradient id="paint0_linear_1349_2003" x1="0" y1="0.183594" x2="0" y2="35.9994" gradientUnits="userSpaceOnUse">
<stop stop-color="#9DA6FE"/>
<stop offset="1" stop-color="#DADEFF" stop-opacity="0.01"/>
</linearGradient>
</defs>
</svg>

    </div>


  <div className=" bg-gradient-to-bl from-[#8FD3F4] to-[#84FAB0] rounded-[12px] flex justify-between items-center px-[16px] py-[30px]">
    <div className="div">
      <p className="text-[16px] text-[#FFFFFF] font-medium ">  Catering partners </p>
      <p className="text-[24px] text-[#FFFFFF] font-medium "> 52k </p>
    </div>
    <svg width="54" height="36" viewBox="0 0 54 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.0448533 35.9994C0.0448533 35.9994 -0.0448533 9.50768 0.0448533 9.28471C0.71305 7.62391 1.64938 6.02548 2.31549 5.91083C3.4488 5.71578 4.09481 8.36351 5.39707 8.36351C6.69933 8.36351 8.03245 6.09193 9.69507 5.91083C11.3577 5.72973 12.3905 7.44232 13.8543 7.44232C15.3181 7.44232 16.9958 0.993957 18.6322 0.993957C20.2687 0.993957 21.837 11.1271 24.0228 11.1271C26.2085 11.1271 26.652 2.54056 28.0265 2.54056C29.401 2.54056 30.3164 5.5769 31.6368 5.72659C32.9571 5.87629 32.9571 8.36351 34.0696 8.36351C35.1821 8.36351 36.7748 0.183594 38.5279 0.183594C40.2809 0.183594 42.3058 5.9936 43.5589 5.9936C44.8121 5.9936 44.1251 1.91515 45.6084 1.91515C47.0918 1.91515 46.0011 5.54236 47.485 5.72659C48.9689 5.91083 50.1776 7.44232 51.6208 7.44232C52.0372 7.44232 52.6085 7.11109 53.025 6.17784C53.4416 5.24459 53.8171 3.7449 53.8171 3.7449H54C54 3.7449 54 4.42194 54 4.61756C54 6.75574 54 35.9994 54 35.9994H0.0448533Z" fill="url(#paint0_linear_1349_2003)"/>
<defs>
<linearGradient id="paint0_linear_1349_2003" x1="0" y1="0.183594" x2="0" y2="35.9994" gradientUnits="userSpaceOnUse">
<stop stop-color="#9DA6FE"/>
<stop offset="1" stop-color="#DADEFF" stop-opacity="0.01"/>
</linearGradient>
</defs>
</svg>

    </div>
    <div className=" bg-gradient-to-r from-[#FF5858] to-[#F09819] rounded-[12px] flex justify-between items-center px-[16px] py-[30px]">
    <div className="div">
    <p className="text-[16px] text-[#FFFFFF] font-medium "> Decoration partners </p>
      <p className="text-[24px] text-[#FFFFFF] font-medium ">$3700</p>
    </div>
    <svg width="54" height="36" viewBox="0 0 54 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.0448533 35.9994C0.0448533 35.9994 -0.0448533 9.50768 0.0448533 9.28471C0.71305 7.62391 1.64938 6.02548 2.31549 5.91083C3.4488 5.71578 4.09481 8.36351 5.39707 8.36351C6.69933 8.36351 8.03245 6.09193 9.69507 5.91083C11.3577 5.72973 12.3905 7.44232 13.8543 7.44232C15.3181 7.44232 16.9958 0.993957 18.6322 0.993957C20.2687 0.993957 21.837 11.1271 24.0228 11.1271C26.2085 11.1271 26.652 2.54056 28.0265 2.54056C29.401 2.54056 30.3164 5.5769 31.6368 5.72659C32.9571 5.87629 32.9571 8.36351 34.0696 8.36351C35.1821 8.36351 36.7748 0.183594 38.5279 0.183594C40.2809 0.183594 42.3058 5.9936 43.5589 5.9936C44.8121 5.9936 44.1251 1.91515 45.6084 1.91515C47.0918 1.91515 46.0011 5.54236 47.485 5.72659C48.9689 5.91083 50.1776 7.44232 51.6208 7.44232C52.0372 7.44232 52.6085 7.11109 53.025 6.17784C53.4416 5.24459 53.8171 3.7449 53.8171 3.7449H54C54 3.7449 54 4.42194 54 4.61756C54 6.75574 54 35.9994 54 35.9994H0.0448533Z" fill="url(#paint0_linear_1349_2003)"/>
<defs>
<linearGradient id="paint0_linear_1349_2003" x1="0" y1="0.183594" x2="0" y2="35.9994" gradientUnits="userSpaceOnUse">
<stop stop-color="#9DA6FE"/>
<stop offset="1" stop-color="#DADEFF" stop-opacity="0.01"/>
</linearGradient>
</defs>
</svg>

    </div>
   

  </div>



  <div className="grid grid-cols-1    xl:grid-cols-3 gap-6">
    <div className="col-span-1 lg:col-span-2">
      
<Tiffinsellers />
<Cateringservice/>
<Decorationservice/>
    </div>
    <div className="col-span-1  lg:col-span-1">
      <TiffcateringRate />
      <TiffnSelleRight/>
      <CateringRigh/>
    </div>
 
  </div>


 

    </section>
  )
}

export default Tiffincatering