import React, { useRef } from "react";
import Chart from "react-apexcharts";

const TotalExpenses = () => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",'Jul',"Aug",'Sept']; // Dummy data for months
  const money = ['0', '6k', '12k', '20k', '30k', '40k', '50k', '60k', '70k']; // Dummy data for money


  const options = {
    chart: {
      type: "line",
      height: 350,
      
      id: "basic-line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    xaxis: {
      categories: months,
      labels: {
        style: {
          fontSize: '14px', // Set font size for x-axis labels
          fontFamily: 'Poppins', // Set font family for x-axis labels
        }
      }
      
    },
     stroke: {
      curve: 'smooth',
      colors: ['#F85A5F'], // Apply color to the chart line
    },
    markers: {
      colors: ['#F85A5F'], // Apply color to the chart markers
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value + "k"; // Append "k" to the y-axis labels
        },
        style: {
          fontSize: '14px', // Set font size for y-axis labels
          fontFamily: 'Poppins', // Set font family for y-axis labels
        }
      },
    },
    tooltip: {
      style: {
        fontSize: "14px", // Set font size for the tooltip
        fontFamily: "Poppins", 
      colors: ['#F85A5F'], // Apply color to the chart markers
      // Set font family for the tooltip
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
  };

  const series = [
    {
      name: "Money",
      data: money,
    
    stroke: {
      width: 4, // Set the stroke width for the line
      curve: "smooth", // Set the curve style for the line
      hover: {
        size: 7, // Set the size of the hover effect
        sizeOffset: 3, // Set the offset for the size of the hover effect
        strokeWidth: 4, // Set the stroke width for the hover effect
        
      },
    },
  }
  ];
  return (
    <div  className="bg-[#FFFFFF] rounded-[10px] px-[16px] py-[10px] mt-[2rem]" >
    

    {/* card header */}
    <div className="flex flex-wrap  items-center  gap-4 ">  
    <div className="flex flex-wrap  gap-2 sm:gap-8 items-center">   
          <p className="text-[16px] text-[#3B3B3B] font-semibold ">Total expenses<span className="text-[#F33F41] text-[14px] font-medium ">(65K) </span></p>
    <div className="flex items-center gap-2">
    
    <span className="bg-[#45A843] w-[12px] h-[12px] rounded-full"></span>
    <p className="text-[12px] text-[#949494] font-medium  ">$33K(Settled)</p>
    </div>
    <div className="flex items-center gap-2">
    
    <span className="bg-[#F33F41] w-[12px] h-[12px] rounded-full"></span>
    <p className=" text-[12px] text-[#949494] font-medium  "> $32K(Pending)  </p>
    </div>
    
          </div>
    
   
    
    
          </div>
    
    
    
            <div >
              <Chart options={options} series={series} type="line" height={330} />
            </div>
    
    
    
        </div>
  )
}

export default TotalExpenses