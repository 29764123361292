import React from 'react'
import Header from '../../../Components/Header/Header'

const Chat = () => {
  return (
    <section className={`p-3 sm:p-5 `}>



<Header/>
      

        <div className="flex flex-wrap justify-between items-center  mt-4">   
        <p className=" text-[20px] text-[#3B3B3B] font-medium " >Chat</p>

        
        </div>


        <div className="grid grid-cols-4   mt-[32px] h-[688px]">


<div className="bg-[#FFFFFF] border-r border-[#AEAEAE]  col-span-1 md:col-span-2 lg:col-span-1">
{/* header */}
<div className=" px-[4px]  lg:px-[8px]  xl:px-[16px] mt-[16px] border-b border-dashed border-[#AEAEAE] pb-[18px]">   
<p className=" text-[16px] text-[#121212] font-medium " >   Message</p>
<p className=" text-[12px] text-[#12121266] font-light mt-[8px]" >  You have 5 unread messages  </p>   
</div>


<div className="px-[4px]  lg:px-[8px]  xl:px-[16px]  border-b border-[#AEAEAE] flex flex-wrap items-center gap-[10px] md:gap-[48px] pt-[6px]">  

<p className=" text-[14px] text-[#949494] font-medium " >   Customer</p>

<p className=" text-[14px] text-[#3B3B3B] font-medium border-b border-[#F7575B] " >   Seller</p>

 </div>


 {/* chat list */}

<div className="chtcontact_container">  
<div className="flex flex-col md:flex-row  md:justify-between items-start md:items-center mt-[16px] px-[4px]  lg:px-[6px] xl:px-[16px]  border-b border-dashed border-[#AEAEAE] pb-[15px]">
{/* left */}
<div className="flex flex-col md:flex-row items-start md:items-center gap-[8px] xl:gap-[16px]">


<div className=" relative">  
<img src='https://s3-alpha-sig.figma.com/img/dcde/f27d/74efe11c154ead302457897622a3919b?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=CXV20NVgPOY7I-7r96FeYlBvQd7AXTy9HDJmLsceu~OazOuq16Nx~L2X3ZO7zq7f8dlrp5b0V9QDQioE3keNNYDwgiz3mXd5TI6D1lloo9u4Erh04cBpRcdky0ZyBB~Sn9h4i5BGfrPHpYizPOIOvS4lQiwZrG4aisYPXL~HUzLjHBQ7yEVVzCyl4qcARs-NO1D3VHU6sPG91nbgfzUIMUDaqc9hAS0k7Qmbinuxe7iBMBc9garvtyAPqgfE39Lx6ELXfe6QA7aU2TQA2E8fVpqAEq6r0KoNGjgGoavMTJFvHiDVDizh-tD0tLl9AH2OJrBhQFpzTKSoFJ9HYAzrOg__' className='w-[40px] h-[40px]  xl:w-[48px] xl:h-[48px] rounded-full object-cover'  />
<div className="flex justify-center items-center w-[16px] h-[16px] rounded-full bg-[#F33F41] absolute top-0  right-0">
<p className="text-[10px] text-[#FFFFFF] font-medium  "> 1 </p>

</div>

</div>
<div className="flex flex-col">

<div className='flex items-center gap-[4px] xl:gap-[6px]'>


    <p className="text-[14px] xl:text-[16px] text-[#3B3B3B] font-medium  "> Zaika </p>
<span className="bg-[#45A843] w-[6px] h-[6px] rounded-full"></span>
</div>

<p className='text-[12px] xl:text-[14px] text-[#949494] font-normal'>Why is he acting like that....</p>

</div>

</div>

{/* right */}

<div >
    <p className=" text-[12px] text-[#949494] font-medium ">2m</p>
</div>

</div>
<div className="flex flex-col md:flex-row  md:justify-between items-start md:items-center mt-[16px] px-[4px]  lg:px-[6px] xl:px-[16px]   border-b border-dashed border-[#AEAEAE] pb-[15px]">
{/* left */}
<div className="flex flex-col lg:flex-row items-start md:items-center gap-[8px] xl:gap-[16px]">


<div className=" relative">  
<img src='https://s3-alpha-sig.figma.com/img/dcde/f27d/74efe11c154ead302457897622a3919b?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=CXV20NVgPOY7I-7r96FeYlBvQd7AXTy9HDJmLsceu~OazOuq16Nx~L2X3ZO7zq7f8dlrp5b0V9QDQioE3keNNYDwgiz3mXd5TI6D1lloo9u4Erh04cBpRcdky0ZyBB~Sn9h4i5BGfrPHpYizPOIOvS4lQiwZrG4aisYPXL~HUzLjHBQ7yEVVzCyl4qcARs-NO1D3VHU6sPG91nbgfzUIMUDaqc9hAS0k7Qmbinuxe7iBMBc9garvtyAPqgfE39Lx6ELXfe6QA7aU2TQA2E8fVpqAEq6r0KoNGjgGoavMTJFvHiDVDizh-tD0tLl9AH2OJrBhQFpzTKSoFJ9HYAzrOg__' className='w-[40px] h-[40px]  xl:w-[48px] xl:h-[48px] rounded-full object-cover'  />

<div className="flex justify-center items-center w-[16px] h-[16px] rounded-full bg-[#F33F41] absolute top-0  right-0">
<p className="text-[10px] text-[#FFFFFF] font-medium  "> 1 </p>

</div>

</div>
<div className="flex flex-col">

<div className='flex items-center gap-[4px] xl:gap-[6px]'>


    <p className="text-[14px] xl:text-[16px] text-[#3B3B3B] font-medium  "> Zaika </p>
<span className="bg-[#45A843] w-[6px] h-[6px] rounded-full"></span>
</div>

<p className='text-[12px] xl:text-[14px] text-[#949494] font-normal'>Why is he acting like that....</p>

</div>

</div>

{/* right */}

<div >
    <p className=" text-[12px] text-[#949494] font-medium ">2m</p>
</div>

</div>

</div>


</div>


<div className="bg-[#FFFFFF]  col-span-3 md:col-span-2 lg:col-span-3 relative">

<div className=" border-b border-[#AEAEAE] px-[12px] lg:px-[36px] py-[18px]">

<div className="flex justify-between items-center  ">
{/* left */}
<div className="flex items-center gap-[16px]">

<div className=" relative">  
<img src='https://s3-alpha-sig.figma.com/img/dcde/f27d/74efe11c154ead302457897622a3919b?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=CXV20NVgPOY7I-7r96FeYlBvQd7AXTy9HDJmLsceu~OazOuq16Nx~L2X3ZO7zq7f8dlrp5b0V9QDQioE3keNNYDwgiz3mXd5TI6D1lloo9u4Erh04cBpRcdky0ZyBB~Sn9h4i5BGfrPHpYizPOIOvS4lQiwZrG4aisYPXL~HUzLjHBQ7yEVVzCyl4qcARs-NO1D3VHU6sPG91nbgfzUIMUDaqc9hAS0k7Qmbinuxe7iBMBc9garvtyAPqgfE39Lx6ELXfe6QA7aU2TQA2E8fVpqAEq6r0KoNGjgGoavMTJFvHiDVDizh-tD0tLl9AH2OJrBhQFpzTKSoFJ9HYAzrOg__' className='w-[48px] h-[48px] rounded-full object-cover'  />
<div className="flex justify-center items-center w-[16px] h-[16px] rounded-full bg-[#F33F41] absolute top-0  right-0">
<p className="text-[10px] text-[#FFFFFF] font-medium  "> 1 </p>

</div>

</div>
<div className="flex flex-col">

<div className='flex items-center gap-[6px]'>


    <p className="text-[16px] text-[#3B3B3B] font-medium  "> Zaika </p>
<span className="bg-[#45A843] w-[6px] h-[6px] rounded-full"></span>
</div>

<p className='text-[14px] text-[#949494] font-normal'>Typing..........</p>
</div>

</div>


{/* right */}

<div >
<svg width="5" height="16" viewBox="0 0 5 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.0898437 14C0.0898437 15.1 0.989844 16 2.08984 16C3.18984 16 4.08984 15.1 4.08984 14C4.08984 12.9 3.18984 12 2.08984 12C0.989844 12 0.0898436 12.9 0.0898437 14ZM0.0898431 2C0.0898432 3.1 0.989843 4 2.08984 4C3.18984 4 4.08984 3.1 4.08984 2C4.08984 0.899999 3.18984 -1.35505e-07 2.08984 -8.74228e-08C0.989843 -3.93403e-08 0.0898431 0.9 0.0898431 2ZM0.0898434 8C0.0898434 9.1 0.989843 10 2.08984 10C3.18984 10 4.08984 9.1 4.08984 8C4.08984 6.9 3.18984 6 2.08984 6C0.989843 6 0.0898434 6.9 0.0898434 8Z" fill="#3B3B3B"/>
</svg>

</div>

</div>

</div>



<div className="flex flex-wrap justify-end items-center px-[12px] lg:px-[36px] mt-[100px] sm:mt-[392px]">
<div className="message_container">
    <div className="flex flex-wrap justify-between items-end gap-[10px] md:gap-[30px]">
<div className="left  bg-[#F54A4C] px-[15px] py-[14px] rounded-tl-[8px] rounded-tr-[8px] rounded-bl-[8px]">
<p  className=" text-[14px] text-[#FFFFFF] font-normal ">Good morning!, how can I help you today?</p>

</div>
<div className="right  bg-[#F33F41] p-[7px]  rounded-full w-[32px] h-[32px]">
<p className='text-[12px] text-[#FFFFFF] font-medium '>ME</p>

</div>


    </div>

    <div className="flex flex-wrap  justify-end items-center gap-2 mt-2 ">
<p className=" text-[12px] text-[#121212] font-normal ">2 minutes ago</p>
<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 8.56796L2.92857 9.46796L5.71429 12.168L14 4.16797" stroke="#2F80ED"/>
<path d="M6 8.56796L6.92857 9.46796L9.71429 12.168L18 4.16797" stroke="#2F80ED"/>
</svg>


    </div>
</div>

</div>



<div className="px-[12px] lg:px-[36px] py-[18px] absolute bottom-2  w-full flex  flex-col sm:flex-row items-start sm:items-center gap-2  sm:gap-6"> 

{/* input  */}

<div className="flex flex-wrap flex-1 w-full items-center gap-4 bg-[#F6F7FB] rounded-[8px] p-[6px] sm:p-[8px] ">

<textarea type="text"  placeholder='Type your message here..' className='flex-1 w-full border-none bg-[#F6F7FB] focus:border-none p-[4px] '/>

<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.23704 12.6356L15.2827 5.83971C15.3582 5.7669 15.9622 5.18439 16.9184 4.86887C18.252 4.45626 19.6108 4.79606 20.6928 5.83971C21.8 6.88336 22.1271 8.16973 21.6994 9.45609C21.3974 10.3784 20.7683 11.0094 20.6928 11.0822L13.1187 18.3878C11.8102 19.6499 8.539 21.2761 5.51941 18.3878C2.52498 15.4996 4.23608 12.3201 5.51941 11.0822L13.6471 3.21844C13.9491 2.92719 14.4272 2.92719 14.7292 3.21844C15.0311 3.50969 15.0311 3.97084 14.7292 4.26209L6.62659 12.1259C6.50077 12.223 3.95929 14.7714 6.62659 17.3442C9.2184 19.8441 11.7599 17.6112 12.0367 17.3442L19.6108 10.0143C19.6108 10.0143 20.0386 9.60172 20.2399 9.01921C20.4915 8.24254 20.3154 7.56295 19.6108 6.88336C18.101 5.4271 16.6416 6.64065 16.3648 6.88336L9.31906 13.655C9.06742 13.8977 8.89128 14.3103 9.31906 14.7229C9.74683 15.1355 10.1494 14.9413 10.4011 14.7229L15.2827 10.0143C15.5847 9.74734 16.088 9.74734 16.3648 10.0143C16.6667 10.3056 16.6667 10.791 16.3648 11.0822L11.4831 15.7666C10.8037 16.3976 9.41971 16.9073 8.23704 15.7666C7.0292 14.6258 7.55763 13.2666 8.23704 12.6356Z" fill="#3B3B3B"/>
</svg>



</div>

<div className="flex justify-center items-center p-[16px]  rounded-[8px]  bg-[#FA777A]">
<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1349_10475)">
<path d="M1.00395 20.4695L3.73069 11.234L1.00395 1.99841C0.666624 0.869777 1.84727 -0.134984 2.94359 0.374278L24.1671 9.99521C25.2494 10.4907 25.2494 12.0047 24.1671 12.4865L2.94359 22.1074C1.84727 22.6029 0.666624 21.6119 1.00395 20.4695Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1349_10475">
<rect width="24.0335" height="24" fill="white" transform="translate(0.945312)"/>
</clipPath>
</defs>
</svg>


</div>


</div>




</div>

</div>


<p className=" text-[20px] text-[#3B3B3B] font-medium mt-[32px]" >Order details</p>

<div className="flex flex-wrap mt-[24px]  p-[16px] md:p-[24px] bg-[#FFFFFF] rounded-[10px]" >

<div className="flex flex-wrap items-center gap-[24px]">
    {/* left input */}
<div className="flex-1 flex justify-between items-center gap-2 px-[6px] md:px-[20px] py-[14px] bg-[#F7F8FB] rounded-[8px]">
<input type="text" placeholder="Order ID" class="flex-1 text-[#949494] text-16 font-normal border-none bg-transparent focus:outline-none ring-0 focus:border-none" />

    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke="#949494" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 22L20 20" stroke="#949494" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</div>
<div className='bg-gradient-to-b  from-[#FB6D72] to-[#F33F41] px-[24px] py-[14px] rounded-[10px] cursor-pointer' >
<p className=" text-[16px] text-[#FFFFFF] font-medium " >Search</p>

</div>
</div>
</div>

        </section>
  )
}

export default Chat