import React from "react";


import { useMediaQuery } from "react-responsive";

import TotalGrowth from "../TotalGrowth";
import Tiffins from "../Tiffins";
import Caterings from "../Caterings";
import AdsRevenuecompoennt from "../../../Components/Ads/AdsRevenueCompoennt";
import Bannersactivity from "../../../Components/Ads/Bannersactivity";
import Header from "../../../Components/Header/Header";
import Advertise from "../../../Components/Ads/Advertise";

const Ads = () => {
    const isLaptop = useMediaQuery({
        query: '(max-width: 1023px)'
      })
      
  return (
    <section className="bg-[#F9F9F9] p-3 sm:p-5"
       
    >


<Header/>



        <p className=" text-[20px] text-[#3B3B3B] font-medium mt-8" >Ads</p>

    




  <div className="grid grid-cols-1    xl:grid-cols-3 gap-6">
    <div className="col-span-1 lg:col-span-2">
        <AdsRevenuecompoennt/>

<Advertise/>
    </div>
    <div className="col-span-1  lg:col-span-1">
      <TotalGrowth title={'10 more banners uploaded today'} />
      <Bannersactivity  />
    </div>
 
  </div>

    </section>
  )
}

export default Ads