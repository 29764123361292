
import { useMediaQuery } from "react-responsive";
import TotalRevenue from "../TotalRevenue";
import AdsRevenue from "../AdsRevenue";
import Decoration from "../Decoration";
import TotalGrowth from "../TotalGrowth";
import Tiffins from "../Tiffins";
import Caterings from "../Caterings";
import AdsRevenuecompoennt from "../../../Components/Ads/AdsRevenueCompoennt";
import Bannersactivity from "../../../Components/Ads/Bannersactivity";
import Header from "../../../Components/Header/Header";
import ReferRevenue from "../../../Components/Refer and earn/ReferREvenue";
import ReferTRansaction from "../../../Components/Refer and earn/ReferTRansaction";
import ReferMembers from "../../../Components/Refer and earn/ReferMembers";

const ReferandEarn = () => {
   
  return (
    <section className="bg-[#F9F9F9] p-3 sm:p-5"
       
    >



        <Header/>




        <p className=" text-[20px] text-[#3B3B3B] font-medium mt-8" >Refer and earn</p>

    




  <div className="grid grid-cols-1    xl:grid-cols-3 gap-6">
    <div className="col-span-1 lg:col-span-2">
        <ReferRevenue/>

<ReferTRansaction/>
    </div>
    <div className="col-span-1  lg:col-span-1">
      <TotalGrowth  title={'120 more accounts referred today'}/>
      <ReferMembers  />
    </div>
 
  </div>

    </section>
  )
}

export default ReferandEarn