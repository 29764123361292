import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { useMediaQuery } from "react-responsive";
import ApexCharts from "apexcharts";
const Giftdistribution = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 375px)",
  });

  useEffect(() => {
    const options = {
      series: [
        {
          name: "Dummy Data",
          data: [44, 55],
          color: "rgb(236, 84, 88)",
        },
        {
          name: "Dummy Data",
          data: [53, 32],
          color: "rgb(243, 244, 246)",
        },
      ],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        // stackType: "100%",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
        dataLabels: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      // title: {
      //   text: "100% Stacked Bar",
      // },
      xaxis: {
        categories: [2008, 2009],
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "K";
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
      },
    };

    const chartElement = document.querySelector("#chart");
    if (chartElement) {
      const chart = new ApexCharts(chartElement, options);
      chart.render();
    }

    // Cleanup function to destroy the chart when component unmounts
    return () => {
      if (chartElement) {
        chartElement.innerHTML = ""; // Clear the chart container
      }
    };
  }, []);

  return (
    <div className="bg-[#FFFFFF] rounded-[10px] px-[16px] py-[20px] mt-[2rem]">
      {/* card header */}
      <div className="flex flex-wrap gap-4 justify-between items-center mb-2 md:mb-0">
        <p className="text-[16px] text-[#3B3B3B] font-semibold">
          Gift distribution
        </p>
        <div className="flex items-center gap-2">
          <svg
            width="22"
            height="24"
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* SVG Path */}
          </svg>
          <input
            type="date"
            placeholder="Today"
            name=""
            id=""
            className="border border-1 border-[#686868] text-[14px] text-[#44444F] font-normal rounded-[8px] p-[6px]"
          />
        </div>
      </div>
      <p className="text-[30px] text-[#000000] font-medium">$3200</p>
      <div id="chart">{/* Chart will be rendered here */}</div>
      <div className="flex flex-wrap justify-between items-center">
        <div className="flex items-center gap-2">
          <span className="bg-gradient-to-b from-[#FB6D72] to-[#F33F41] w-[16px] h-[16px] rounded-full"></span>
          <p className="text-[14px] text-[#686868] font-medium text-center mt-4 md:mt-0">
            Pending payments
          </p>
        </div>
        <p className="text-[14px] text-[#3B3B3B] font-medium text-center mt-4 md:mt-0">
          $44K
        </p>
      </div>
      <div className="flex flex-wrap justify-between items-center mt-2">
        <div className="flex items-center gap-2">
          <span className="bg-[#45A843] w-[16px] h-[16px] rounded-full"></span>
          <p className="text-[14px] text-[#686868] font-medium text-center mt-4 md:mt-0">
            Distributed prizes
          </p>
        </div>
        <p className="text-[14px] text-[#3B3B3B] font-medium text-center mt-4 md:mt-0">
          $52K
        </p>
      </div>
    </div>
  );
};

export default Giftdistribution;
