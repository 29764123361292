import React from 'react'

const Bankaccounts = ({modaltoggle}) => {
  return (
    <div  className="bg-[#FFFFFF] rounded-[10px] px-[16px] py-[20px] mt-[2rem]" >
        
    
    {/* card header */}
          <p className="  text-[16px] text-[#3B3B3B] font-medium ">Bank accounts</p>



<div className="flex  justify-between items-center border-b border-[#F1F1F5] pb-[20px] mt-[40px]">

<div className="flex  items-center">   
    <img src='https://s3-alpha-sig.figma.com/img/f4d4/8a4b/ac8bc48a06427b53e5860be1dbfa2fec?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Z5eZg3J6g6434IXh86YEVfxwcrjVemmQy1GWOEpKEpiv1dTmQYvnBfnMFdxVbNFxwAlkN0LAS5oj2bMTIHVXcNFi4CbdNGqg2-oq0Q0olXNYwIo7H-MOR~OY3HdYZyMoFaNHLHT4u2ItiXmSBojPkqozUFXE63t4~PbPWv3KooUbRTWA6-YS~PXzacaxuPK-ATQVLVihiZcfqRwobDvXS9R9OYCQc6NqCjRUneosDkdy7lul8RVImMwxE9aaUC93Krdbw8kbFEPW2-aso3YWrs353m0tJScm43AQAgs58C9vvey3nAVVJhxxnKAjpIh80-rKk-fbMYaxtbg3ReXskw__' className='w-[48px] h-[48px] rounded-full object-cover'   />

<div className="flex flex-col gap-1 ml-6 md:ml-12">

<p className="  text-[16px] text-[#3B3B3B] font-semibold ">**** **** 3434  </p>
<p className='text-[10px] text-[#949494] font-normal'>Royal bank of canada</p>

</div>
</div>


<div className="flex gap-2 items-center ">

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.4736 0L16.0015 2.52787L14.0744 4.4558L11.5465 1.92793L13.4736 0ZM4.21484 11.7866H6.74272L12.8829 5.64643L10.3551 3.11855L4.21484 9.25876V11.7866Z" fill="#3888FF"/>
<path d="M13.482 14.315H4.34626C4.32435 14.315 4.3016 14.3234 4.27969 14.3234C4.25189 14.3234 4.22408 14.3159 4.19543 14.315H1.68525V2.51826H7.4547L9.13995 0.833008H1.68525C0.755835 0.833008 0 1.588 0 2.51826V14.315C0 15.2453 0.755835 16.0003 1.68525 16.0003H13.482C13.929 16.0003 14.3576 15.8227 14.6737 15.5067C14.9897 15.1906 15.1672 14.762 15.1672 14.315V7.01113L13.482 8.69638V14.315Z" fill="#3888FF"/>
</svg>

<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.1111 2.7C15.3469 2.7 15.573 2.79482 15.7397 2.9636C15.9064 3.13239 16 3.3613 16 3.6C16 3.83869 15.9064 4.06761 15.7397 4.2364C15.573 4.40518 15.3469 4.5 15.1111 4.5H14.2222L14.2196 4.5639L13.3902 16.3278C13.3583 16.7819 13.1576 17.2069 12.8285 17.5172C12.4995 17.8275 12.0665 18 11.6169 18H4.38222C3.93257 18 3.49961 17.8275 3.17056 17.5172C2.84151 17.2069 2.64081 16.7819 2.60889 16.3278L1.77956 4.5648C1.77821 4.54323 1.77761 4.52161 1.77778 4.5H0.888889C0.653141 4.5 0.427048 4.40518 0.260349 4.2364C0.0936505 4.06761 0 3.83869 0 3.6C0 3.3613 0.0936505 3.13239 0.260349 2.9636C0.427048 2.79482 0.653141 2.7 0.888889 2.7H15.1111ZM12.4418 4.5H3.55822L4.38311 16.2H11.6169L12.4418 4.5ZM9.77778 0C10.0135 0 10.2396 0.0948211 10.4063 0.263604C10.573 0.432387 10.6667 0.661305 10.6667 0.9C10.6667 1.13869 10.573 1.36761 10.4063 1.5364C10.2396 1.70518 10.0135 1.8 9.77778 1.8H6.22222C5.98647 1.8 5.76038 1.70518 5.59368 1.5364C5.42698 1.36761 5.33333 1.13869 5.33333 0.9C5.33333 0.661305 5.42698 0.432387 5.59368 0.263604C5.76038 0.0948211 5.98647 0 6.22222 0H9.77778Z" fill="#F33F41"/>
</svg>



</div>


</div>
<div className="flex  justify-between items-center border-b border-[#F1F1F5] pb-[20px] mt-[40px]">

<div className="flex  items-center">   
    <img src='https://s3-alpha-sig.figma.com/img/f4d4/8a4b/ac8bc48a06427b53e5860be1dbfa2fec?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Z5eZg3J6g6434IXh86YEVfxwcrjVemmQy1GWOEpKEpiv1dTmQYvnBfnMFdxVbNFxwAlkN0LAS5oj2bMTIHVXcNFi4CbdNGqg2-oq0Q0olXNYwIo7H-MOR~OY3HdYZyMoFaNHLHT4u2ItiXmSBojPkqozUFXE63t4~PbPWv3KooUbRTWA6-YS~PXzacaxuPK-ATQVLVihiZcfqRwobDvXS9R9OYCQc6NqCjRUneosDkdy7lul8RVImMwxE9aaUC93Krdbw8kbFEPW2-aso3YWrs353m0tJScm43AQAgs58C9vvey3nAVVJhxxnKAjpIh80-rKk-fbMYaxtbg3ReXskw__' className='w-[48px] h-[48px] rounded-full object-cover'   />

<div className="flex flex-col gap-1 ml-6 md:ml-12">

<p className="  text-[16px] text-[#3B3B3B] font-semibold ">**** **** 3434  </p>
<p className='text-[10px] text-[#949494] font-normal'>Royal bank of canada</p>

</div>
</div>


<div className="flex gap-2 items-center ">

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.4736 0L16.0015 2.52787L14.0744 4.4558L11.5465 1.92793L13.4736 0ZM4.21484 11.7866H6.74272L12.8829 5.64643L10.3551 3.11855L4.21484 9.25876V11.7866Z" fill="#3888FF"/>
<path d="M13.482 14.315H4.34626C4.32435 14.315 4.3016 14.3234 4.27969 14.3234C4.25189 14.3234 4.22408 14.3159 4.19543 14.315H1.68525V2.51826H7.4547L9.13995 0.833008H1.68525C0.755835 0.833008 0 1.588 0 2.51826V14.315C0 15.2453 0.755835 16.0003 1.68525 16.0003H13.482C13.929 16.0003 14.3576 15.8227 14.6737 15.5067C14.9897 15.1906 15.1672 14.762 15.1672 14.315V7.01113L13.482 8.69638V14.315Z" fill="#3888FF"/>
</svg>

<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.1111 2.7C15.3469 2.7 15.573 2.79482 15.7397 2.9636C15.9064 3.13239 16 3.3613 16 3.6C16 3.83869 15.9064 4.06761 15.7397 4.2364C15.573 4.40518 15.3469 4.5 15.1111 4.5H14.2222L14.2196 4.5639L13.3902 16.3278C13.3583 16.7819 13.1576 17.2069 12.8285 17.5172C12.4995 17.8275 12.0665 18 11.6169 18H4.38222C3.93257 18 3.49961 17.8275 3.17056 17.5172C2.84151 17.2069 2.64081 16.7819 2.60889 16.3278L1.77956 4.5648C1.77821 4.54323 1.77761 4.52161 1.77778 4.5H0.888889C0.653141 4.5 0.427048 4.40518 0.260349 4.2364C0.0936505 4.06761 0 3.83869 0 3.6C0 3.3613 0.0936505 3.13239 0.260349 2.9636C0.427048 2.79482 0.653141 2.7 0.888889 2.7H15.1111ZM12.4418 4.5H3.55822L4.38311 16.2H11.6169L12.4418 4.5ZM9.77778 0C10.0135 0 10.2396 0.0948211 10.4063 0.263604C10.573 0.432387 10.6667 0.661305 10.6667 0.9C10.6667 1.13869 10.573 1.36761 10.4063 1.5364C10.2396 1.70518 10.0135 1.8 9.77778 1.8H6.22222C5.98647 1.8 5.76038 1.70518 5.59368 1.5364C5.42698 1.36761 5.33333 1.13869 5.33333 0.9C5.33333 0.661305 5.42698 0.432387 5.59368 0.263604C5.76038 0.0948211 5.98647 0 6.22222 0H9.77778Z" fill="#F33F41"/>
</svg>



</div>


</div>
<div className="flex  justify-between items-center border-b border-[#F1F1F5] pb-[20px] mt-[40px]">

<div className="flex  items-center">   
    <img src='https://s3-alpha-sig.figma.com/img/f4d4/8a4b/ac8bc48a06427b53e5860be1dbfa2fec?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Z5eZg3J6g6434IXh86YEVfxwcrjVemmQy1GWOEpKEpiv1dTmQYvnBfnMFdxVbNFxwAlkN0LAS5oj2bMTIHVXcNFi4CbdNGqg2-oq0Q0olXNYwIo7H-MOR~OY3HdYZyMoFaNHLHT4u2ItiXmSBojPkqozUFXE63t4~PbPWv3KooUbRTWA6-YS~PXzacaxuPK-ATQVLVihiZcfqRwobDvXS9R9OYCQc6NqCjRUneosDkdy7lul8RVImMwxE9aaUC93Krdbw8kbFEPW2-aso3YWrs353m0tJScm43AQAgs58C9vvey3nAVVJhxxnKAjpIh80-rKk-fbMYaxtbg3ReXskw__' className='w-[48px] h-[48px] rounded-full object-cover'   />

<div className="flex flex-col gap-1 ml-6 md:ml-12">

<p className="  text-[16px] text-[#3B3B3B] font-semibold ">**** **** 3434  </p>
<p className='text-[10px] text-[#949494] font-normal'>Royal bank of canada</p>

</div>
</div>


<div className="flex gap-2 items-center ">

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.4736 0L16.0015 2.52787L14.0744 4.4558L11.5465 1.92793L13.4736 0ZM4.21484 11.7866H6.74272L12.8829 5.64643L10.3551 3.11855L4.21484 9.25876V11.7866Z" fill="#3888FF"/>
<path d="M13.482 14.315H4.34626C4.32435 14.315 4.3016 14.3234 4.27969 14.3234C4.25189 14.3234 4.22408 14.3159 4.19543 14.315H1.68525V2.51826H7.4547L9.13995 0.833008H1.68525C0.755835 0.833008 0 1.588 0 2.51826V14.315C0 15.2453 0.755835 16.0003 1.68525 16.0003H13.482C13.929 16.0003 14.3576 15.8227 14.6737 15.5067C14.9897 15.1906 15.1672 14.762 15.1672 14.315V7.01113L13.482 8.69638V14.315Z" fill="#3888FF"/>
</svg>

<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.1111 2.7C15.3469 2.7 15.573 2.79482 15.7397 2.9636C15.9064 3.13239 16 3.3613 16 3.6C16 3.83869 15.9064 4.06761 15.7397 4.2364C15.573 4.40518 15.3469 4.5 15.1111 4.5H14.2222L14.2196 4.5639L13.3902 16.3278C13.3583 16.7819 13.1576 17.2069 12.8285 17.5172C12.4995 17.8275 12.0665 18 11.6169 18H4.38222C3.93257 18 3.49961 17.8275 3.17056 17.5172C2.84151 17.2069 2.64081 16.7819 2.60889 16.3278L1.77956 4.5648C1.77821 4.54323 1.77761 4.52161 1.77778 4.5H0.888889C0.653141 4.5 0.427048 4.40518 0.260349 4.2364C0.0936505 4.06761 0 3.83869 0 3.6C0 3.3613 0.0936505 3.13239 0.260349 2.9636C0.427048 2.79482 0.653141 2.7 0.888889 2.7H15.1111ZM12.4418 4.5H3.55822L4.38311 16.2H11.6169L12.4418 4.5ZM9.77778 0C10.0135 0 10.2396 0.0948211 10.4063 0.263604C10.573 0.432387 10.6667 0.661305 10.6667 0.9C10.6667 1.13869 10.573 1.36761 10.4063 1.5364C10.2396 1.70518 10.0135 1.8 9.77778 1.8H6.22222C5.98647 1.8 5.76038 1.70518 5.59368 1.5364C5.42698 1.36761 5.33333 1.13869 5.33333 0.9C5.33333 0.661305 5.42698 0.432387 5.59368 0.263604C5.76038 0.0948211 5.98647 0 6.22222 0H9.77778Z" fill="#F33F41"/>
</svg>



</div>


</div>


<div className="flex justify-center items-center mt-[40px]">

<div className=" border border-[#F9777A] rounded-[8px] px-[24px] py-[9px] flex justify-center items-center cursor-pointer" onClick={modaltoggle}>   
<p className='text-[#F9777A] text-[14px]  font-medium'>  Add bank account  </p>   
</div>
</div>


          </div>
  )
}

export default Bankaccounts