// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active-link {
  position: relative;
}

.menu .active-link:after,
.menu .active-link:before {
  content: '';
  position: absolute;
  width: 32px; 
  height: 32px; 
  right: 0px;
}

.menu .active-link:after {
  top: -32px; /* Adjust the top position */
  background: radial-gradient(circle at top left, #fc6f74 32px, #F9F9F9 33px);
}

.menu .active-link:before {
  bottom: -32px; /* Adjust the bottom position */
  background: radial-gradient(circle at bottom left, #fc6f74 32px, #F9F9F9 33px);
}
`, "",{"version":3,"sources":["webpack://./src/Pages/admin/SideBar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;;EAEE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,UAAU,EAAE,4BAA4B;EACxC,2EAA2E;AAC7E;;AAEA;EACE,aAAa,EAAE,+BAA+B;EAC9C,8EAA8E;AAChF","sourcesContent":[".active-link {\n  position: relative;\n}\n\n.menu .active-link:after,\n.menu .active-link:before {\n  content: '';\n  position: absolute;\n  width: 32px; \n  height: 32px; \n  right: 0px;\n}\n\n.menu .active-link:after {\n  top: -32px; /* Adjust the top position */\n  background: radial-gradient(circle at top left, #fc6f74 32px, #F9F9F9 33px);\n}\n\n.menu .active-link:before {\n  bottom: -32px; /* Adjust the bottom position */\n  background: radial-gradient(circle at bottom left, #fc6f74 32px, #F9F9F9 33px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
