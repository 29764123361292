import React, { useRef } from 'react'

const TotalUsers = () => {
  const ref = useRef();

  return (
    <div  className="bg-[#FFFFFF] rounded-[10px]  py-[20px] mt-[1rem]" >
    

    {/* card header */}
    <div className="flex flex-wrap justify-between px-[16px] items-center  gap-4 ">  

    <div className="flex gap-8 items-center">   
          <p className="text-[16px] text-[#3B3B3B] font-semibold ">Total users</p>
    <div className="flex items-center gap-2">
    
    <span className="bg-[#45A843] w-[12px] h-[12px] rounded-full"></span>
    <p className="text-[12px] text-[#949494] font-medium font-Roboto "> 14K active users </p>

    </div>
   
    
          </div>
    
    <div className=" flex flex-wrap items-center gap-2 ">    
    
    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.61552 12.2968C5.61552 11.6506 5.16662 11.2646 4.3751 11.2646C4.05202 11.2646 3.83353 11.2961 3.71875 11.3267V13.402C3.85478 13.4326 4.02142 13.4437 4.25012 13.4437C5.09436 13.4437 5.61552 13.016 5.61552 12.2968ZM10.5177 11.2851C10.1632 11.2851 9.93364 11.3165 9.79761 11.348V15.9458C9.93364 15.9772 10.1521 15.9772 10.3502 15.9772C11.7887 15.9875 12.7274 15.1951 12.7274 13.5168C12.7384 12.057 11.8831 11.2851 10.5177 11.2851Z" fill="#F33F41"/>
    <path d="M19.9097 8.58436H19.3333V5.80339C19.3328 5.78573 19.3311 5.76811 19.3282 5.75068C19.3291 5.63883 19.2896 5.53043 19.2168 5.44546L14.5893 0.160686L14.5859 0.157285C14.5271 0.0935133 14.4522 0.0468071 14.3691 0.0221049L14.3435 0.0136029C14.3082 0.00479575 14.272 0.000229389 14.2356 0H2.86259C2.34312 0 1.92143 0.422544 1.92143 0.94116V8.58436H1.345C0.601934 8.58436 0 9.1863 0 9.92936V16.9247C0 17.6669 0.602784 18.2697 1.345 18.2697H1.92143V23.0588C1.92143 23.5775 2.34312 24 2.86259 24H18.3921C18.9108 24 19.3333 23.5775 19.3333 23.0588V18.2697H19.9097C20.6528 18.2697 21.2547 17.6669 21.2547 16.9247V9.92936C21.2547 9.1863 20.652 8.58436 19.9097 8.58436ZM2.86259 0.94116H13.7646V5.75663C13.7646 6.01679 13.9754 6.22679 14.2356 6.22679H18.393V8.58436H2.86259V0.94116ZM14.4167 13.4644C14.4167 14.8095 13.927 15.7379 13.2477 16.3109C12.508 16.9256 11.3823 17.218 10.0059 17.218C9.18204 17.218 8.59882 17.1653 8.20178 17.1143V10.2116C8.78586 10.1181 9.54678 10.0662 10.3502 10.0662C11.6842 10.0662 12.5496 10.306 13.2272 10.817C13.9576 11.3585 14.4167 12.224 14.4167 13.4644ZM2.14333 17.1458V10.2116C2.63304 10.1283 3.32169 10.0662 4.29091 10.0662C5.27117 10.0662 5.97003 10.2541 6.43848 10.6291C6.88653 10.9836 7.18835 11.5677 7.18835 12.2555C7.18835 12.9441 6.95965 13.5274 6.54221 13.9236C5.99979 14.4345 5.19721 14.6641 4.2586 14.6641C4.0503 14.6641 3.86241 14.6539 3.71703 14.6326V17.1458H2.14333ZM18.3921 22.8038H2.86259V18.2697H18.3921V22.8038ZM19.7559 11.4214H17.0548V13.0275H19.5782V14.3206H17.0548V17.1458H15.4607V10.1181H19.7559V11.4214Z" fill="#F33F41"/>
    </svg>

    <select    type="date"     placeholder="Today" name="" id="" className="border border-1 border-[#686868] text-[14px] text-[#44444F] font-normal rounded-[8px] p-[6px]" >


<option value="">Grocery</option>

      </select>

    <select    type="date"     placeholder="Today" name="" id="" className="border border-1 border-[#686868] text-[14px] text-[#44444F] font-normal rounded-[8px] p-[6px]" >


<option value="">Alberta</option>

      </select>


    <input    type="date"     placeholder="Today" name="" id="" className="border border-1 border-[#686868] text-[14px] text-[#44444F] font-normal rounded-[8px] p-[6px]" />
    
    </div>
    
    
          </div>
    


          <div className="overflow-x-auto mt-4">
              <table className="w-full text-sm text-left  px-[16px]">
                <thead className="text-[14px]  border-b  border-[#D9D9D9] ">
                  <tr className="px-12">
                    <th className="ps-6 py-3 text-[14px] text-[#949494] font-medium" scope="col">
                    Seller name
                    </th>
                    <th className="px-4 py-3 text-[14px] text-[#949494] font-medium" scope="col">
                    Contact
                    </th>
                    <th className="px-4 py-3 text-[14px] text-[#949494] font-medium" scope="col">
                    Province
                    </th>
                    <th className="px-4 py-3 text-[14px] text-[#949494] font-medium" scope="col">
                    Actions
                    </th>
               
                   
                  </tr>
                </thead>
            

                
                <tbody >
                  <tr className="bg-[#FEF5F5] py-4.5 px-12">
                    <th
                      className="ps-6 py-3  whitespace-nowrap dark:text-white text-[14px] text-[#949494] font-medium "
                      scope="row"
                    >


<div className="flex gap-4 items-center ">   

<div className="flex bg-gradient-to-b  from-[#FB6D72] to-[#F33F41] h-[32px] w-[32px] rounded-full justify-center items-center">
<img src='https://s3-alpha-sig.figma.com/img/2972/ae58/85ed75ae6c420c446671811aa676e788?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=VlOrTT~FlzYJzhHjAk5U39sQv1TyqjCCzgcUVknnjrw9KUAdtW8TgsQLwKFjTIYlDMr4eq0VS-Ppxm7C4YR5VYtXrsgsFdRzVLHo5iymL7X7JPpdjertQmw-vSubKhNAEdT7UvDsdtVGn34mVY4q-c2qFiZGdKQrSLPVfHB24wYfIGltqeDx-d1WqpzEs6Tzw6hjduK8fv9~ZCIMjjZOhE-kmFjfoHjnoDDmcd-ChvC~XhvPD6K1wQFpDnewakjjX5PGw3TQm3BPA26ZBnM6iAWHKV8DAw4UFkRrM-Zc4nryA4V2hEBrj3WXFc8nEaGIjxRua73mpniEq0uoozGVZQ__' className='w-[24px] h-[18px]'  />
 
    
</div>
<div className="flex flex-col ">   


<p className="text-[14px] text-[#333333] font-medium ">Punjabi tiffins</p>
<p className="text-[9px] text-[#949494] font-medium ">Date - 01/01/2024, 10:24 AM</p>
             </div>
                      </div>
                   
                    </th>
                    <td className="px-4 py-3 text-[12px] text-[#949494] font-normal ">  +1 888 343 343331776  </td>
                    <td className="px-4 py-3 text-[14px] text-[#949494] font-medium ">
                  


                  <p className=" text-[14px] text-[#686868] font-medium "> AB </p>    
                      

                      </td>
                    
                    
                    <td className="px-8 py-3 text-[14px] text-[#686868] font-medium ">
                    <svg width="4" height="17" viewBox="0 0 4 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M-8.74228e-08 14.5C-3.93402e-08 15.6 0.9 16.5 2 16.5C3.1 16.5 4 15.6 4 14.5C4 13.4 3.1 12.5 2 12.5C0.9 12.5 -1.35505e-07 13.4 -8.74228e-08 14.5ZM-6.11959e-07 2.5C-5.63877e-07 3.6 0.899999 4.5 2 4.5C3.1 4.5 4 3.6 4 2.5C4 1.4 3.1 0.5 2 0.5C0.899999 0.5 -6.60042e-07 1.4 -6.11959e-07 2.5ZM-3.49691e-07 8.5C-3.01609e-07 9.6 0.9 10.5 2 10.5C3.1 10.5 4 9.6 4 8.5C4 7.4 3.1 6.5 2 6.5C0.9 6.5 -3.97774e-07 7.4 -3.49691e-07 8.5Z" fill="#686868"/>
</svg>


                    </td>
                    
                  </tr>

                  <tr className=" py-4.5 px-12">
                    <th
                      className="ps-6 py-3  whitespace-nowrap dark:text-white text-[14px] text-[#949494] font-medium "
                      scope="row"
                    >


<div className="flex gap-4 items-center ">   

<div className="flex bg-gradient-to-b  from-[#FB6D72] to-[#F33F41] h-[32px] w-[32px] rounded-full justify-center items-center">
<img src='https://s3-alpha-sig.figma.com/img/2972/ae58/85ed75ae6c420c446671811aa676e788?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=VlOrTT~FlzYJzhHjAk5U39sQv1TyqjCCzgcUVknnjrw9KUAdtW8TgsQLwKFjTIYlDMr4eq0VS-Ppxm7C4YR5VYtXrsgsFdRzVLHo5iymL7X7JPpdjertQmw-vSubKhNAEdT7UvDsdtVGn34mVY4q-c2qFiZGdKQrSLPVfHB24wYfIGltqeDx-d1WqpzEs6Tzw6hjduK8fv9~ZCIMjjZOhE-kmFjfoHjnoDDmcd-ChvC~XhvPD6K1wQFpDnewakjjX5PGw3TQm3BPA26ZBnM6iAWHKV8DAw4UFkRrM-Zc4nryA4V2hEBrj3WXFc8nEaGIjxRua73mpniEq0uoozGVZQ__' className='w-[24px] h-[18px]'  />
 
    
</div>
<div className="flex flex-col ">   


<p className="text-[14px] text-[#333333] font-medium ">Punjabi tiffins</p>
<p className="text-[9px] text-[#949494] font-medium ">Date - 01/01/2024, 10:24 AM</p>
             </div>
                      </div>
                   
                    </th>
                    <td className="px-4 py-3 text-[12px] text-[#949494] font-normal ">  +1 888 343 343331776  </td>
                    <td className="px-4 py-3 text-[14px] text-[#949494] font-medium ">
                  


                  <p className=" text-[14px] text-[#686868] font-medium "> AB </p>    
                      

                      </td>
                    
                    
                    <td className="px-8 py-3 text-[14px] text-[#686868] font-medium ">
                    <svg width="4" height="17" viewBox="0 0 4 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M-8.74228e-08 14.5C-3.93402e-08 15.6 0.9 16.5 2 16.5C3.1 16.5 4 15.6 4 14.5C4 13.4 3.1 12.5 2 12.5C0.9 12.5 -1.35505e-07 13.4 -8.74228e-08 14.5ZM-6.11959e-07 2.5C-5.63877e-07 3.6 0.899999 4.5 2 4.5C3.1 4.5 4 3.6 4 2.5C4 1.4 3.1 0.5 2 0.5C0.899999 0.5 -6.60042e-07 1.4 -6.11959e-07 2.5ZM-3.49691e-07 8.5C-3.01609e-07 9.6 0.9 10.5 2 10.5C3.1 10.5 4 9.6 4 8.5C4 7.4 3.1 6.5 2 6.5C0.9 6.5 -3.97774e-07 7.4 -3.49691e-07 8.5Z" fill="#686868"/>
</svg>


                    </td>
                    
                  </tr>
                  <tr className="bg-[#FEF5F5] py-4.5 px-12">
                    <th
                      className="ps-6 py-3  whitespace-nowrap dark:text-white text-[14px] text-[#949494] font-medium "
                      scope="row"
                    >


<div className="flex gap-4 items-center ">   

<div className="flex bg-gradient-to-b  from-[#FB6D72] to-[#F33F41] h-[32px] w-[32px] rounded-full justify-center items-center">
<img src='https://s3-alpha-sig.figma.com/img/2972/ae58/85ed75ae6c420c446671811aa676e788?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=VlOrTT~FlzYJzhHjAk5U39sQv1TyqjCCzgcUVknnjrw9KUAdtW8TgsQLwKFjTIYlDMr4eq0VS-Ppxm7C4YR5VYtXrsgsFdRzVLHo5iymL7X7JPpdjertQmw-vSubKhNAEdT7UvDsdtVGn34mVY4q-c2qFiZGdKQrSLPVfHB24wYfIGltqeDx-d1WqpzEs6Tzw6hjduK8fv9~ZCIMjjZOhE-kmFjfoHjnoDDmcd-ChvC~XhvPD6K1wQFpDnewakjjX5PGw3TQm3BPA26ZBnM6iAWHKV8DAw4UFkRrM-Zc4nryA4V2hEBrj3WXFc8nEaGIjxRua73mpniEq0uoozGVZQ__' className='w-[24px] h-[18px]'  />
 
    
</div>
<div className="flex flex-col ">   


<p className="text-[14px] text-[#333333] font-medium ">Punjabi tiffins</p>
<p className="text-[9px] text-[#949494] font-medium ">Date - 01/01/2024, 10:24 AM</p>
             </div>
                      </div>
                   
                    </th>
                    <td className="px-4 py-3 text-[12px] text-[#949494] font-normal ">  +1 888 343 343331776  </td>
                    <td className="px-4 py-3 text-[14px] text-[#949494] font-medium ">
                  


                  <p className=" text-[14px] text-[#686868] font-medium "> AB </p>    
                      

                      </td>
                    
                    
                    <td className="px-8 py-3 text-[14px] text-[#686868] font-medium ">
                    <svg width="4" height="17" viewBox="0 0 4 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M-8.74228e-08 14.5C-3.93402e-08 15.6 0.9 16.5 2 16.5C3.1 16.5 4 15.6 4 14.5C4 13.4 3.1 12.5 2 12.5C0.9 12.5 -1.35505e-07 13.4 -8.74228e-08 14.5ZM-6.11959e-07 2.5C-5.63877e-07 3.6 0.899999 4.5 2 4.5C3.1 4.5 4 3.6 4 2.5C4 1.4 3.1 0.5 2 0.5C0.899999 0.5 -6.60042e-07 1.4 -6.11959e-07 2.5ZM-3.49691e-07 8.5C-3.01609e-07 9.6 0.9 10.5 2 10.5C3.1 10.5 4 9.6 4 8.5C4 7.4 3.1 6.5 2 6.5C0.9 6.5 -3.97774e-07 7.4 -3.49691e-07 8.5Z" fill="#686868"/>
</svg>


                    </td>
                    
                  </tr>
                  <tr className=" py-4.5 px-12">
                    <th
                      className="ps-6 py-3  whitespace-nowrap dark:text-white text-[14px] text-[#949494] font-medium "
                      scope="row"
                    >


<div className="flex gap-4 items-center ">   

<div className="flex bg-gradient-to-b  from-[#FB6D72] to-[#F33F41] h-[32px] w-[32px] rounded-full justify-center items-center">
<img src='https://s3-alpha-sig.figma.com/img/2972/ae58/85ed75ae6c420c446671811aa676e788?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=VlOrTT~FlzYJzhHjAk5U39sQv1TyqjCCzgcUVknnjrw9KUAdtW8TgsQLwKFjTIYlDMr4eq0VS-Ppxm7C4YR5VYtXrsgsFdRzVLHo5iymL7X7JPpdjertQmw-vSubKhNAEdT7UvDsdtVGn34mVY4q-c2qFiZGdKQrSLPVfHB24wYfIGltqeDx-d1WqpzEs6Tzw6hjduK8fv9~ZCIMjjZOhE-kmFjfoHjnoDDmcd-ChvC~XhvPD6K1wQFpDnewakjjX5PGw3TQm3BPA26ZBnM6iAWHKV8DAw4UFkRrM-Zc4nryA4V2hEBrj3WXFc8nEaGIjxRua73mpniEq0uoozGVZQ__' className='w-[24px] h-[18px]'  />
 
    
</div>
<div className="flex flex-col ">   


<p className="text-[14px] text-[#333333] font-medium ">Punjabi tiffins</p>
<p className="text-[9px] text-[#949494] font-medium ">Date - 01/01/2024, 10:24 AM</p>
             </div>
                      </div>
                   
                    </th>
                    <td className="px-4 py-3 text-[12px] text-[#949494] font-normal ">  +1 888 343 343331776  </td>
                    <td className="px-4 py-3 text-[14px] text-[#949494] font-medium ">
                  


                  <p className=" text-[14px] text-[#686868] font-medium "> AB </p>    
                      

                      </td>
                    
                    
                    <td className="px-8 py-3 text-[14px] text-[#686868] font-medium ">
                    <svg width="4" height="17" viewBox="0 0 4 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M-8.74228e-08 14.5C-3.93402e-08 15.6 0.9 16.5 2 16.5C3.1 16.5 4 15.6 4 14.5C4 13.4 3.1 12.5 2 12.5C0.9 12.5 -1.35505e-07 13.4 -8.74228e-08 14.5ZM-6.11959e-07 2.5C-5.63877e-07 3.6 0.899999 4.5 2 4.5C3.1 4.5 4 3.6 4 2.5C4 1.4 3.1 0.5 2 0.5C0.899999 0.5 -6.60042e-07 1.4 -6.11959e-07 2.5ZM-3.49691e-07 8.5C-3.01609e-07 9.6 0.9 10.5 2 10.5C3.1 10.5 4 9.6 4 8.5C4 7.4 3.1 6.5 2 6.5C0.9 6.5 -3.97774e-07 7.4 -3.49691e-07 8.5Z" fill="#686868"/>
</svg>


                    </td>
                    
                  </tr>
                  <tr className="bg-[#FEF5F5] py-4.5 px-12">
                    <th
                      className="ps-6 py-3  whitespace-nowrap dark:text-white text-[14px] text-[#949494] font-medium "
                      scope="row"
                    >


<div className="flex gap-4 items-center ">   

<div className="flex bg-gradient-to-b  from-[#FB6D72] to-[#F33F41] h-[32px] w-[32px] rounded-full justify-center items-center">
<img src='https://s3-alpha-sig.figma.com/img/2972/ae58/85ed75ae6c420c446671811aa676e788?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=VlOrTT~FlzYJzhHjAk5U39sQv1TyqjCCzgcUVknnjrw9KUAdtW8TgsQLwKFjTIYlDMr4eq0VS-Ppxm7C4YR5VYtXrsgsFdRzVLHo5iymL7X7JPpdjertQmw-vSubKhNAEdT7UvDsdtVGn34mVY4q-c2qFiZGdKQrSLPVfHB24wYfIGltqeDx-d1WqpzEs6Tzw6hjduK8fv9~ZCIMjjZOhE-kmFjfoHjnoDDmcd-ChvC~XhvPD6K1wQFpDnewakjjX5PGw3TQm3BPA26ZBnM6iAWHKV8DAw4UFkRrM-Zc4nryA4V2hEBrj3WXFc8nEaGIjxRua73mpniEq0uoozGVZQ__' className='w-[24px] h-[18px]'  />
 
    
</div>
<div className="flex flex-col ">   


<p className="text-[14px] text-[#333333] font-medium ">Punjabi tiffins</p>
<p className="text-[9px] text-[#949494] font-medium ">Date - 01/01/2024, 10:24 AM</p>
             </div>
                      </div>
                   
                    </th>
                    <td className="px-4 py-3 text-[12px] text-[#949494] font-normal ">  +1 888 343 343331776  </td>
                    <td className="px-4 py-3 text-[14px] text-[#949494] font-medium ">
                  


                  <p className=" text-[14px] text-[#686868] font-medium "> AB </p>    
                      

                      </td>
                    
                    
                    <td className="px-8 py-3 text-[14px] text-[#686868] font-medium ">
                    <svg width="4" height="17" viewBox="0 0 4 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M-8.74228e-08 14.5C-3.93402e-08 15.6 0.9 16.5 2 16.5C3.1 16.5 4 15.6 4 14.5C4 13.4 3.1 12.5 2 12.5C0.9 12.5 -1.35505e-07 13.4 -8.74228e-08 14.5ZM-6.11959e-07 2.5C-5.63877e-07 3.6 0.899999 4.5 2 4.5C3.1 4.5 4 3.6 4 2.5C4 1.4 3.1 0.5 2 0.5C0.899999 0.5 -6.60042e-07 1.4 -6.11959e-07 2.5ZM-3.49691e-07 8.5C-3.01609e-07 9.6 0.9 10.5 2 10.5C3.1 10.5 4 9.6 4 8.5C4 7.4 3.1 6.5 2 6.5C0.9 6.5 -3.97774e-07 7.4 -3.49691e-07 8.5Z" fill="#686868"/>
</svg>


                    </td>
                    
                  </tr>
                </tbody>
              </table>
            </div>

        


            <div className="flex justify-center items-center mt-8">
            

            <div className="flex gap-2 items-center">
            
            {/* prev */}
            <div className="flex justify-center items-center py-[8px] px-[11px] border border-[#D9D9D9DD] bg-[#ffffff]" >
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.9654 14C6.9049 13.9958 6.8895 13.9964 6.82959 13.986C6.69489 13.9628 6.56533 13.9117 6.45103 13.8367C6.39322 13.7988 6.34277 13.7547 6.29237 13.7077L0.292113 7.70743C0.250711 7.66308 0.23916 7.65298 0.202409 7.60453C0.161157 7.55012 0.125505 7.49147 0.0961541 7.42977C-0.0320514 7.16021 -0.0320514 6.84034 0.0961541 6.57078C0.125505 6.50908 0.161157 6.45043 0.202409 6.39602C0.23916 6.34762 0.250711 6.33747 0.292113 6.29317L6.29237 0.292913C6.33672 0.251511 6.34687 0.23991 6.39527 0.203209C6.52238 0.106755 6.67239 0.0416518 6.82959 0.0145006C6.94185 -0.00480021 7.0572 -0.00480021 7.16946 0.0145006C7.25921 0.0300013 7.34677 0.0578025 7.42902 0.0969042C7.74143 0.245461 7.95894 0.553624 7.99434 0.897738C8.00829 1.03374 7.99404 1.17225 7.95269 1.30256C7.91824 1.41111 7.86519 1.51356 7.79638 1.60427C7.75963 1.65272 7.74808 1.66282 7.70668 1.70717L2.41355 7.0003L7.70668 12.2934L7.75328 12.3433C7.79153 12.3903 7.80243 12.4013 7.83574 12.4521C7.89819 12.5473 7.94419 12.6531 7.97114 12.7637C7.99269 12.8523 8.00209 12.9437 7.99899 13.0347C7.98719 13.3804 7.79118 13.7027 7.48967 13.8722C7.39042 13.928 7.28166 13.9667 7.16946 13.986C7.10955 13.9964 7.09415 13.9958 7.03365 14C7.0109 14 6.98815 14 6.9654 14Z" fill="#3B3B3B"/>
            </svg>
            
            </div>
            
            
            <div className="text-[14px] text-[#3B3B3B] font-medium">1 of 1</div>
            
            {/* Next */}
            
            
            <div className="flex justify-center items-center py-[8px] px-[11px] border border-[#D9D9D9DD] bg-[#ffffff]" >
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.0346 6.08934e-07C1.0951 0.00415099 1.1105 0.00365031 1.17041 0.0139509C1.30511 0.0372015 1.43467 0.0882536 1.54897 0.163257C1.60678 0.201159 1.65723 0.245261 1.70763 0.292313L7.70789 6.29257C7.74929 6.33692 7.76084 6.34702 7.79759 6.39547C7.83884 6.44988 7.87449 6.50853 7.90385 6.57023C8.03205 6.83979 8.03205 7.15966 7.90385 7.42922C7.87449 7.49092 7.83884 7.54957 7.79759 7.60398C7.76084 7.65238 7.74929 7.66253 7.70789 7.70683L1.70763 13.7071C1.66328 13.7485 1.65313 13.7601 1.60473 13.7968C1.47762 13.8932 1.32761 13.9583 1.17041 13.9855C1.05815 14.0048 0.942797 14.0048 0.830543 13.9855C0.740789 13.97 0.653235 13.9422 0.570981 13.9031C0.258568 13.7545 0.0410585 13.4464 0.00565712 13.1023C-0.00829324 12.9663 0.0059575 12.8278 0.0473093 12.6974C0.0817608 12.5889 0.134813 12.4864 0.203616 12.3957C0.240367 12.3473 0.251918 12.3372 0.29332 12.2928L5.58645 6.9997L0.293319 1.70657L0.246717 1.65667C0.208465 1.60967 0.197564 1.59872 0.164263 1.54792C0.10181 1.45271 0.0558084 1.34686 0.0288571 1.23625C0.00730593 1.14775 -0.00209445 1.05635 0.00100594 0.965342C0.0128062 0.619577 0.208815 0.297313 0.510328 0.127756C0.609582 0.0719535 0.718336 0.033302 0.830541 0.013951C0.890444 0.00365033 0.905845 0.00415101 0.966347 6.14901e-07C0.989098 6.12912e-07 1.01185 6.10923e-07 1.0346 6.08934e-07Z" fill="#3B3B3B"/>
            </svg>
            
            
            </div>
            
            </div>
            
                        </div>
            
            



    </div>
  )
}

export default TotalUsers